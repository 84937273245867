<template>
  <v-dialog
    v-model="dialog"
    class="width-dialog-sm-and-down dialog-box"
    width="430"
  >
    <div class="dialog-container width-dialog-sm-and-down">
      <div class="dialog-header">
        <h2>Create Referrer</h2>
      </div>
      <div
        class="dialog-body"
      >

        <div
          class="transparent">
          <el-form
            ref="form"
            :model="formData"
            :rules="rules">
            <div class="form-item">
              <span>Name</span>
              <el-form-item prop="name">
                <v-input v-model="formData.name"></v-input>
              </el-form-item>
            </div>
            <div class="form-item">
              <span>Contact Phone Number</span>
              <el-form-item prop="contact_phone">
                <v-input v-model="formData.contact_phone"></v-input>
              </el-form-item>
            </div>
          </el-form>
        </div>

      </div>
      <div
        class="dialog-footer"
        align="right">
        <div class="btn-container">
          <v-button
            size="small"
            class="button-theme-block"
            @click="dialog = false">Cancel
          </v-button>
          <v-button
            size="small"
            type="primary"
            class="button-theme"
            @click="handlerSave">{{isEdit?'Edit':'Add'}} Refer
          </v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import {} from '@/api/referrer'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
export default {
  name: 'createReferrerDialog',
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isEdit:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      dialog:false,
      isSent:false,
      date:'',
      formData:{
        name:'',
        contact_phone:''
      }

    }
  },
  watch:{
    form:{
      immediate:true,
      handler(val){
        if(val)
        {
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
            }
          })
        }
      }
    }
  },
  methods: {
    showReferDialog(){
      this.dialog = !this.dialog
    },
    handlerSave(){
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$emit('refresh',this.formData)
          this.$refs.form.resetFields()
          this.dialog = false
        } else {
        }
      })
    },
    network() {
      return {

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-box{
  width: 430px!important;
  ::v-deep .el-dialog{
    width: 430px;
  }
  .dialog-container {
    padding: 30px;
    color: #616266;
    width: 430px;
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      //margin: 0 -15px;
      //padding: 30px 30px 0 30px;
      //   border-bottom: 1px solid #dddfe7;
      h1 {
        font-size: 24px;
      }
    }
    .dialog-body{
      .sent-content{
        margin: 20px 0;
        color: #718096;
        .el-checkbox{
          margin-right: 10px;
        }
      }
      .form-item{
        margin: 0 0px 30px 0px;
        span{
          color: #718096;
        }
      }
    }

    .transparent{
      margin-bottom: 20px;
    }
  }
}
</style>
